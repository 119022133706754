import React from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import axios, { AxiosError } from 'axios';
import config from 'libs/config';
import { ErrorHandler } from 'libs/error/index';

export class SentryErrorHandler implements ErrorHandler {
  handle(error?: Error) {
    Sentry.captureException(error);
  }
}

export const initSentry = () => {
  const isEnabled = config.sentry.isEnabled;

  if (isEnabled) {
    Sentry.init({
      dsn: config.sentry.dsn,
      integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect: React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        Sentry.extraErrorDataIntegration(),
      ],
      beforeSend(event, hint) {
        const { originalException } = hint;

        const isAxiosError = axios.isAxiosError(originalException);

        if (isAxiosError) {
          const axiosError = originalException as AxiosError;

          event.fingerprint = [
            '{{ default }}',
            String(axiosError?.response?.status),
            String(axiosError?.config?.url),
          ];
        }

        return event;
      },
      environment: import.meta.env.MODE,
      tracesSampleRate: 0.1,
    });
  }
};
