import React, { useEffect, useRef } from 'react';
import {
  QueryParamConfig,
  QueryParamOptions,
  useQueryParam,
} from 'use-query-params';

export const useInterval = (callback: () => void, delayInMs: number) => {
  const callbackRef = useRef<() => void>();

  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    if (delayInMs !== null) {
      const interval = setInterval(
        () => callbackRef.current?.(),
        delayInMs || 0
      );
      return () => clearInterval(interval);
    }

    return undefined;
  }, [delayInMs]);
};

export const useKampusQueryParam = <
  TypeToEncode,
  TypeFromDecode = TypeToEncode
>(
  name: string,
  paramConfig?: QueryParamConfig<TypeToEncode, TypeFromDecode>,
  options?: QueryParamOptions
) => {
  const newOptions: QueryParamOptions = {
    updateType: 'replaceIn',
    ...options,
  };

  return useQueryParam<TypeToEncode, TypeFromDecode>(
    name,
    paramConfig,
    newOptions
  );
};

export const usePrevious = <T>(value: T) => {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

/**
 * Utility to log changed dependencies in useEffect to the console.
 */
export const useEffectDebugger = <T extends Array<unknown>>(
  dependencies: T,
  label = '[use-effect-debugger] ',
  effectHook: React.EffectCallback = () => {
    /* Do Nothing */
  },
  dependencyNames = []
) => {
  const previousDeps = usePrevious(dependencies);

  const changedDeps = dependencies.reduce(
    (accum: T, dependency: unknown, index: number) => {
      // eslint-disable-next-line
      // @ts-ignore
      const previousDep = previousDeps[index];

      if (dependency !== previousDep) {
        const keyName = dependencyNames[index] || index;
        return {
          ...accum,
          [keyName]: {
            before: previousDep,
            after: dependency,
          },
        };
      }

      return accum;
    },
    {} as T
  );

  if (Object.keys(changedDeps).length) {
    // eslint-disable-next-line no-console
    console.log(label, changedDeps);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effectHook, dependencies);
};
