import { createSlice } from '@reduxjs/toolkit';
import { ReportWithCampaignSpecificationsOutputDto } from '@kortxio/hub-api';
import {
  getAllReportsForCampaign,
  getReportDefaultsForCampaign,
  runBudgetGroupQuery,
  runDigitalOutOfHomeCampaignQuery,
  runPixelNameQuery,
  runQuery,
} from 'features/reports/campaigns/async';
import {
  fulfilled,
  initialRequestState,
  pending,
  rejected,
  requestReducer,
} from 'features/shared/request';
import { customReportsCases } from './custom-reports-slice';
import { CampaignReportsState } from './types';

export const initialState: CampaignReportsState = {
  customReports: {
    data: undefined,
    request: initialRequestState,
  },
  reports: {
    data: undefined,
    request: initialRequestState,
  },
  selectedReport: undefined,
  reportDefaults: {
    data: undefined,
    request: initialRequestState,
  },
  queryResults: undefined,
  pixelNameQueryResults: {
    data: undefined,
    request: initialRequestState,
  },
  digitalOutOfHomeCampaignQueryResults: {
    data: undefined,
    request: initialRequestState,
  },
  budgetGroupQueryResults: {
    data: undefined,
    request: initialRequestState,
  },
};

export const slice = createSlice({
  name: 'campaignReports',
  initialState,
  reducers: {
    setSelectedReport(
      state,
      {
        payload: report,
      }: { payload: ReportWithCampaignSpecificationsOutputDto | undefined }
    ) {
      state.selectedReport = report;
      state.reportDefaults = {
        data: undefined,
        request: initialRequestState,
      };
      state.queryResults = undefined;
      state.pixelNameQueryResults = {
        data: undefined,
        request: initialRequestState,
      };
      state.digitalOutOfHomeCampaignQueryResults = {
        data: undefined,
        request: initialRequestState,
      };
    },
    resetReports: (state) => {
      state.reports = {
        data: undefined,
        request: initialRequestState,
      };
      state.customReports = {
        data: undefined,
        request: initialRequestState,
      };
      state.selectedReport = undefined;
      state.reportDefaults = {
        data: undefined,
        request: initialRequestState,
      };
      state.queryResults = undefined;
      state.pixelNameQueryResults = {
        data: undefined,
        request: initialRequestState,
      };
      state.digitalOutOfHomeCampaignQueryResults = {
        data: undefined,
        request: initialRequestState,
      };
    },
  },
  extraReducers: (builder) => {
    customReportsCases(builder);
    builder.addCase(
      getAllReportsForCampaign.fulfilled,
      (state, { meta, payload }) => {
        if (state.reports.request.id === meta.requestId) {
          state.reports.data = payload;
          state.reports.request = requestReducer(
            state.reports.request,
            fulfilled({ meta })
          );
        }
      }
    );
    builder.addCase(getAllReportsForCampaign.pending, (state, { meta }) => {
      state.reports.data = undefined;
      state.reports.request = requestReducer(
        state.reports.request,
        pending({ meta })
      );
    });
    builder.addCase(getAllReportsForCampaign.rejected, (state, { meta }) => {
      state.reports.data = undefined;
      state.reports.request = requestReducer(
        state.reports.request,
        rejected({ meta })
      );
    });

    builder.addCase(
      getReportDefaultsForCampaign.fulfilled,
      (state, { meta, payload }) => {
        if (state.reportDefaults.request.id === meta.requestId) {
          state.reportDefaults.data = payload;
          state.reportDefaults.request = requestReducer(
            state.reportDefaults.request,
            fulfilled({ meta })
          );
        }
      }
    );

    builder.addCase(getReportDefaultsForCampaign.pending, (state, { meta }) => {
      state.reportDefaults.data = undefined;
      state.reportDefaults.request = requestReducer(
        state.reportDefaults.request,
        pending({ meta })
      );
    });

    builder.addCase(
      getReportDefaultsForCampaign.rejected,
      (state, { meta }) => {
        state.reportDefaults.data = undefined;
        state.reportDefaults.request = requestReducer(
          state.reportDefaults.request,
          rejected({ meta })
        );
      }
    );

    builder.addCase(runQuery.fulfilled, (state, { meta, payload }) => {
      const { arg } = meta || {};
      const { id, queryName } = arg || {};

      if (id === state.selectedReport?.id) {
        const queryResults =
          state.queryResults != undefined
            ? state.queryResults.filter((value) => value.name !== queryName)
            : [];

        const queryResultRequestForQueryName = state.queryResults?.find(
          (value) => value?.name === queryName
        )?.request;

        const queryResultsForQueryName = {
          name: queryName,
          data: payload,
          request: requestReducer(
            queryResultRequestForQueryName,
            fulfilled({ meta })
          ),
        };

        state.queryResults = [...queryResults, queryResultsForQueryName];
      }
    });

    builder.addCase(runQuery.pending, (state, { meta }) => {
      const { arg } = meta || {};
      const { queryName } = arg || {};

      const queryResults =
        state.queryResults != undefined
          ? state.queryResults.filter((value) => value.name !== queryName)
          : [];

      const queryResultRequestForQueryName = state.queryResults?.find(
        (value) => value?.name === queryName
      )?.request;

      const queryResultsForQueryName = {
        name: queryName,
        data: undefined,
        request: requestReducer(
          queryResultRequestForQueryName,
          pending({ meta })
        ),
      };

      state.queryResults = [...queryResults, queryResultsForQueryName];
    });

    builder.addCase(runQuery.rejected, (state, { meta }) => {
      const { arg } = meta || {};
      const { queryName } = arg || {};

      const queryResults =
        state.queryResults != undefined
          ? state.queryResults.filter((value) => value.name !== queryName)
          : [];

      const queryResultRequestForQueryName = state.queryResults?.find(
        (value) => value?.name === queryName
      )?.request;

      const queryResultsForQueryName = {
        name: queryName,
        data: undefined,
        request: requestReducer(
          queryResultRequestForQueryName,
          rejected({ meta })
        ),
      };

      state.queryResults = [...queryResults, queryResultsForQueryName];
    });

    builder.addCase(runPixelNameQuery.fulfilled, (state, { meta, payload }) => {
      if (state.pixelNameQueryResults.request.id === meta.requestId) {
        state.pixelNameQueryResults.data = payload;
        state.pixelNameQueryResults.request = requestReducer(
          state.pixelNameQueryResults.request,
          fulfilled({ meta })
        );
      }
    });

    builder.addCase(runPixelNameQuery.pending, (state, { meta }) => {
      state.pixelNameQueryResults.data = undefined;
      state.pixelNameQueryResults.request = requestReducer(
        state.pixelNameQueryResults.request,
        pending({ meta })
      );
    });

    builder.addCase(runPixelNameQuery.rejected, (state, { meta }) => {
      state.pixelNameQueryResults.data = undefined;
      state.pixelNameQueryResults.request = requestReducer(
        state.pixelNameQueryResults.request,
        rejected({ meta })
      );
    });

    builder.addCase(
      runDigitalOutOfHomeCampaignQuery.fulfilled,
      (state, { meta, payload }) => {
        if (
          state.digitalOutOfHomeCampaignQueryResults.request.id ===
          meta.requestId
        ) {
          state.digitalOutOfHomeCampaignQueryResults.data = payload;
          state.digitalOutOfHomeCampaignQueryResults.request = requestReducer(
            state.digitalOutOfHomeCampaignQueryResults.request,
            fulfilled({ meta })
          );
        }
      }
    );

    builder.addCase(
      runDigitalOutOfHomeCampaignQuery.pending,
      (state, { meta }) => {
        state.digitalOutOfHomeCampaignQueryResults.data = undefined;
        state.digitalOutOfHomeCampaignQueryResults.request = requestReducer(
          state.digitalOutOfHomeCampaignQueryResults.request,
          pending({ meta })
        );
      }
    );

    builder.addCase(
      runDigitalOutOfHomeCampaignQuery.rejected,
      (state, { meta }) => {
        state.digitalOutOfHomeCampaignQueryResults.data = undefined;
        state.digitalOutOfHomeCampaignQueryResults.request = requestReducer(
          state.digitalOutOfHomeCampaignQueryResults.request,
          rejected({ meta })
        );
      }
    );

    builder.addCase(
      runBudgetGroupQuery.fulfilled,
      (state, { meta, payload }) => {
        if (state.budgetGroupQueryResults.request.id === meta.requestId) {
          state.budgetGroupQueryResults.data = payload;
          state.budgetGroupQueryResults.request = requestReducer(
            state.budgetGroupQueryResults.request,
            fulfilled({ meta })
          );
        }
      }
    );

    builder.addCase(runBudgetGroupQuery.pending, (state, { meta }) => {
      state.budgetGroupQueryResults.data = undefined;
      state.budgetGroupQueryResults.request = requestReducer(
        state.budgetGroupQueryResults.request,
        pending({ meta })
      );
    });

    builder.addCase(runBudgetGroupQuery.rejected, (state, { meta }) => {
      state.budgetGroupQueryResults.data = undefined;
      state.budgetGroupQueryResults.request = requestReducer(
        state.budgetGroupQueryResults.request,
        rejected({ meta })
      );
    });
  },
});

const { actions, reducer } = slice;

export const { setSelectedReport, resetReports } = actions;

export default reducer;
