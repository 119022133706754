import {
  CustomReportDto,
  CustomReportDtoDestinationEnum,
  LookerQueryOutputDto,
  ReportDataDto,
  ReportDefaultsDto,
  ReportWithCampaignSpecificationsOutputDto,
} from '@kortxio/hub-api';
import { ReportObjective } from 'features/reports/campaigns/components/ReportToolbar/types';
import { RequestState } from 'features/shared/request';
import { ObjectValues } from 'libs/types/types';

export type DateRange = {
  startDate: Date | undefined;
  endDate: Date | undefined;
};

export interface LookerReportWithCampaignSpecificationsOutputDto
  extends ReportWithCampaignSpecificationsOutputDto {
  queries?: Array<LookerQueryOutputDto>;
}

export const OverviewQueryPrefixes = {
  OVERVIEW: 'overview',
  OVERVIEW_CTV: 'overview_ctv',
} as const;
export type OverviewQueryPrefix = ObjectValues<typeof OverviewQueryPrefixes>;

export const ReportNames = {
  DIGITAL_OUT_OF_HOME: 'DIGITAL_OUT_OF_HOME',
  PROGRAMMATIC_OVERVIEW: 'PROGRAMMATIC_OVERVIEW',
  PROGRAMMATIC_OVERVIEW_WITH_CTV: 'PROGRAMMATIC_OVERVIEW_WITH_CTV',
  RICH_MEDIA_ENGAGEMENTS: 'RICH_MEDIA_ENGAGEMENTS',
} as const;
export type ReportName = ObjectValues<typeof ReportNames>;

export const DrillDownReportNames = {
  AUDIO: 'AUDIO',
  CTV: 'CTV',
  DISPLAY: 'DISPLAY',
  NATIVE: 'NATIVE',
  VIDEO: 'VIDEO',
} as const;
export type DrillDownReportName = ObjectValues<typeof DrillDownReportNames>;

export type SupportedReportName = ReportName | DrillDownReportName;

export const ReportTabNamesAsParam = {
  AUDIO: 'audio',
  CTV: 'ctv',
  DIGITAL_OUT_OF_HOME: 'digital_out_of_home',
  DISPLAY: 'display',
  NATIVE: 'native',
  PROGRAMMATIC_OVERVIEW: 'overview',
  PROGRAMMATIC_OVERVIEW_WITH_CTV: 'overview_ctv',
  RICH_MEDIA_ENGAGEMENTS: 'rich_media_engagements',
  VIDEO: 'video',
} as const satisfies Record<SupportedReportName, string>;
export type SupportedReportTabNameAsParam = ObjectValues<
  typeof ReportTabNamesAsParam
>;

export const SupportedAdTypes = {
  AUDIO: 'audio',
  CTV: 'ctv',
  DIGITAL_OUT_OF_HOME: 'digital_out_of_home',
  BANNER: 'banner',
  NATIVE: 'native',
  RICH_MEDIA: 'rich_media',
  VIDEO: 'video',
} as const;
export type SupportedAdType = ObjectValues<typeof SupportedAdTypes>;

export const ReportTypes = {
  OVERVIEW: 'overview',
  CUSTOM: 'custom',
  DRILL_DOWN: 'drill-down',
  STANDARD: 'standard',
} as const;
export type ReportType = ObjectValues<typeof ReportTypes>;

export type SupportedReportMetadata = {
  id: string;
  reportName: SupportedReportName;
  tabNameAsParam: SupportedReportTabNameAsParam;
  label: string;
  adTypes?: SupportedAdType[];
  reportType: ReportType;
  availableObjectives: ReportObjective[] | undefined;
};

export type CustomReportMetadata = Omit<
  SupportedReportMetadata,
  'availableObjectives' | 'reportName' | 'tabNameAsParam'
> & {
  availableObjectives?: undefined;
  reportName: string;
  tabNameAsParam: string;
  url: string | undefined;
  destination: CustomReportDtoDestinationEnum | undefined;
};

export const isCustomReportMetadata = (
  val: ReportMetadata | undefined
): val is CustomReportMetadata => {
  return val?.reportType === ReportTypes.CUSTOM;
};

export type ReportMetadata = SupportedReportMetadata | CustomReportMetadata;

export type CampaignReportQueryResult = {
  name: string;
  data: ReportDataDto | undefined;
  request: RequestState;
};

export type RunQueryParams = {
  clientId: number;
  campaignId: number;
  id: string;
  queryId: string;
  queryName: string;
  startDate?: Date;
  endDate?: Date;
  productAdTypes?: string[];
  xandrLineItemIds?: string[];
  xandrPixelNames?: string[];
  digitalOutOfHomeCampaignIds?: string[];
  limit?: number;
};

export type RunQueryParamsWithoutFilters = {
  clientId: number;
  campaignId: number;
  id: string;
  queryId: string;
};

export type CampaignReportsState = {
  customReports: {
    data: CustomReportDto[] | undefined;
    request: RequestState;
  };
  reports: {
    data: ReportWithCampaignSpecificationsOutputDto[] | undefined;
    request: RequestState;
  };
  selectedReport: ReportWithCampaignSpecificationsOutputDto | undefined;
  reportDefaults: {
    data: ReportDefaultsDto | undefined;
    request: RequestState;
  };
  queryResults: CampaignReportQueryResult[] | undefined;
  pixelNameQueryResults: {
    data: ReportDataDto | undefined;
    request: RequestState;
  };
  digitalOutOfHomeCampaignQueryResults: {
    data: ReportDataDto | undefined;
    request: RequestState;
  };
  budgetGroupQueryResults: {
    data: ReportDataDto | undefined;
    request: RequestState;
  };
};
