import {
  StandardUserWithMetadataDto,
  UserWithMetadataDto,
} from '@kortxio/hub-api';
import config from 'libs/config';

interface Fullstory {
  identify(uid: string, userVars?: object): void;
  setUserVars(userVars: object): void;
  getCurrentSessionURL(now?: boolean): string;
  log(msg: string): void;
  log(level: string, msg: string): void;
  shutdown(): void;
  restart(): void;
  consent(consent: boolean): void;
  event(eventName: string, eventProperties: object): void;
  anonymize(): void;
}

declare let FS: Fullstory;

const { isEnabled } = config.fullstory;

export const fullstoryIdentify = (
  user: UserWithMetadataDto & StandardUserWithMetadataDto
) => {
  if (isEnabled) {
    const { id, email, lastName, firstName, type, role, client } = user || {};

    const { id: clientId, name: clientName } = client || {};

    FS.identify(id || 'no-uid', {
      displayName: `${firstName} ${lastName}`,
      email,
      clientId_int: type === 'INTERNAL' ? 0 : clientId,
      clientName_str: type === 'INTERNAL' ? 'KORTX' : clientName,
      userType_str: type,
      userRole_str: role,
    });
  }
};

export const fullstorySetUserVars = (user: UserWithMetadataDto) => {
  if (isEnabled) {
    const { email, lastName, firstName, role } = user || {};

    FS.setUserVars({
      displayName: `${firstName} ${lastName}`,
      email,
      userRole_str: role,
    });
  }
};

export const fullstoryAnonymize = () => {
  if (isEnabled) {
    FS.anonymize();
  }
};
