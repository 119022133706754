import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CustomReportDto,
  ReportDataDto,
  ReportDefaultsDto,
  ReportWithCampaignSpecificationsOutputDto,
} from '@kortxio/hub-api';
import axios from 'axios';
import { format } from 'date-fns';
import { setError } from 'features/error/slice';
import { dateFilterExclusionList } from 'features/reports/campaigns/helper';
import {
  RunQueryParamsWithoutFilters,
  RunQueryParams,
} from 'features/reports/campaigns/types';
import api from 'libs/api';
import { parseApiPayload, toApiError } from 'libs/api/helper';

export const getAllReportsForCampaign = createAsyncThunk<
  ReportWithCampaignSpecificationsOutputDto[],
  {
    clientId: number;
    campaignId: number;
  }
>(
  'campaignReports/getAllReportsForCampaign',
  async ({ clientId, campaignId }, { dispatch, rejectWithValue }) => {
    try {
      const response =
        await api.campaignReports.getAllReportSpecificationsForCampaign(
          clientId,
          campaignId
        );

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);

export const getReportDefaultsForCampaign = createAsyncThunk<
  ReportDefaultsDto,
  {
    clientId: number;
    campaignId: number;
    id: string;
  }
>(
  'campaignReports/getReportDefaultsForCampaign',
  async ({ clientId, campaignId, id }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.campaignReports.getReportDefaultsForCampaign(
        clientId,
        campaignId,
        id
      );

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);

export const runQuery = createAsyncThunk<ReportDataDto, RunQueryParams>(
  'campaignReportRunner/runQueryForCampaign',
  async (
    {
      clientId,
      campaignId,
      id,
      queryId,
      queryName,
      startDate,
      endDate,
      productAdTypes,
      xandrLineItemIds,
      xandrPixelNames,
      digitalOutOfHomeCampaignIds,
      limit,
    },
    { dispatch, rejectWithValue, signal }
  ) => {
    const acceptsDateRange = !dateFilterExclusionList.includes(queryName);
    const hasDateRange =
      acceptsDateRange && startDate !== undefined && endDate !== undefined;

    const axiosCancelTokenSource = axios.CancelToken.source();
    signal.addEventListener('abort', () => {
      axiosCancelTokenSource.cancel();
    });

    try {
      const response = await api.campaignReportRunner.postRunQueryForCampaign(
        clientId,
        campaignId,
        id,
        queryId,
        {
          ...(hasDateRange && {
            dateRange: {
              startDate: format(startDate, 'yyyy-MM-dd'),
              endDate: format(endDate, 'yyyy-MM-dd'),
            },
          }),
          productAdTypes: productAdTypes,
          xandrLineItemIds: xandrLineItemIds,
          xandrPixelNames: xandrPixelNames,
          adomniCampaignIds: digitalOutOfHomeCampaignIds,
          limit,
        },
        {
          cancelToken: axiosCancelTokenSource.token,
        }
      );

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);

export const runPixelNameQuery = createAsyncThunk<
  ReportDataDto,
  RunQueryParamsWithoutFilters
>(
  'campaignReports/runPixelNameQuery',
  async (
    { clientId, campaignId, id, queryId },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await api.campaignReportRunner.postRunQueryForCampaign(
        clientId,
        campaignId,
        id,
        queryId,
        {}
      );

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);

export const runDigitalOutOfHomeCampaignQuery = createAsyncThunk<
  ReportDataDto,
  RunQueryParamsWithoutFilters
>(
  'campaignReports/runDigitalOutOfHomeCampaignQuery',
  async (
    { clientId, campaignId, id, queryId },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await api.campaignReportRunner.postRunQueryForCampaign(
        clientId,
        campaignId,
        id,
        queryId,
        {}
      );

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);

export const runBudgetGroupQuery = createAsyncThunk<
  ReportDataDto,
  RunQueryParamsWithoutFilters
>(
  'campaignReports/runBudgetGroupQuery',
  async (
    { clientId, campaignId, id, queryId },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await api.campaignReportRunner.postRunQueryForCampaign(
        clientId,
        campaignId,
        id,
        queryId,
        {}
      );

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);

export const getCustomReportsForCampaign = createAsyncThunk<
  CustomReportDto[],
  {
    campaignId: number;
  }
>(
  'campaignReports/getCustomReportsForCampaign',
  async ({ campaignId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.customReporting.getCustomReportsForCampaign(
        campaignId
      );
      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);
      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }
      return rejectWithValue(apiError);
    }
  }
);
